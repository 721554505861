<template>
  <div>
    <a-spin :spinning="loading">
      <a-descriptions
        :column="3"
        class="iotplt-descriptions iotplt-descriptions-max-10"
      >
        <a-descriptions-item label="账单批次">
          {{ agentSubscribeTrafficPoolOrder.no }}
        </a-descriptions-item>

        <a-descriptions-item label="流量池名称">
          {{ agentSubscribeTrafficPoolOrder.package_name }}
        </a-descriptions-item>

        <a-descriptions-item label="订购周期数">
          {{ agentSubscribeTrafficPoolOrder.cycles | formatBigNumber }}
        </a-descriptions-item>

        <a-descriptions-item label="操作人">
          {{ agentSubscribeTrafficPoolOrder.operator_name }}
        </a-descriptions-item>

        <a-descriptions-item label="账单时间">
          {{ agentSubscribeTrafficPoolOrder.created_at }}
        </a-descriptions-item>

        <a-descriptions-item label="账单金额(元)">
          {{ agentSubscribeTrafficPoolOrder.total_fee | formatCurrency }}
        </a-descriptions-item>
      </a-descriptions>
    </a-spin>
  </div>
</template>

<script>
import { findAgentSubscribeTrafficPoolOrder } from '@/api/agent_subscribe_traffic_pool_order'

export default {
  name: 'ShowAgentSubscribeTrafficPoolOrder',
  data() {
    return {
      agentSubscribeTrafficPoolOrder: {},
      loading: true,
      data: []
    }
  },
  computed: {
    agentType() {
      return this.$store.getters.userAgentType
    },

    agentSubscribeTrafficPoolOrderId() {
      return parseInt(this.$route.params.subscribe_traffic_pool_order_id)
    }
  },
  created() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      this.loading = true
      findAgentSubscribeTrafficPoolOrder(this.agentSubscribeTrafficPoolOrderId).then((res) => {
        if (res.code === 0) {
          this.agentSubscribeTrafficPoolOrder = res.data

          this.$store.dispatch('ConcatDynamicBreadCrumbs', [
            { redirect: `/agent_consumption_bills`, meta: { title: '消费账单' }},
            { redirect: ``, meta: { title: this.agentSubscribeTrafficPoolOrder.no }}
          ]
          ).then(() => {})
        }
        this.loading = false
      })
    }
  }
}
</script>

